import React from 'react';
import PropTypes from 'prop-types';
import { graphql } from 'gatsby';
import Project from '../templates/Project';
import Content from '../components/Content';
import WorkDone from '../components/WorkDone'
import projects from '../project_data'
import mastImage from '../images/projects/buy-rite/mast.png'
import SkillCreator from '../components/SkillCreator/SkillCreator';
import ProjectLinks from '../components/ProjectLinks/projectLinks';
import FeatureCreator from '../components/FeatureCreator/FeatureCreator';

const BuyRite = ({ data, path }) => {

    return (
        <Project
            title="Buy-Rite Beauty"
            image={mastImage}
            pathname={path}
        >
            <Content header="About">
                <p>
                  As part of the project, Buy-Rite wanted an upgrade from Magento 1 to Magento 2 
                  framework for both their main store and package builder to address the 
                  technical limitations and slow loading speed.
                </p>
                <p>
                  The project also involved a complex data migration process, 
                  transferring vast amount of information from M1 to M2 while revamping the 
                  previous business logic with the new one.
                </p>
                <p>
                  In addition to the framework upgrade, we undertook a complete 
                  redesign of the website, refreshing its look and feel to align with modern design trends.
                </p>
                <div className="project-page-work">
                    <h2 className="project-page-work--title">Accomplishments</h2>
                        <WorkDone  projectData={projects.get('br')} />
                </div>
                <div className="project-page-tech">
                    <SkillCreator projectData={projects.get('br')} />
                </div>
                <div className="project-page-btns project-page-btns--launch-date">
                    <ProjectLinks projectData={projects.get('br')} />
                </div>
                <FeatureCreator projectData={projects.get('br')} />
             </Content>
        </Project>
    )
}


BuyRite.propTypes = {
    // data: PropTypes.object.isRequired,
    path: PropTypes.string.isRequired,
};
  

export default BuyRite
